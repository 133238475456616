import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export function Streetsurveillance() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
				<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Street Surveillance and Central Control Room</h2>
					<div className='row'>
						<img src='/img/00.jpeg' class='img-fluid rounded-top' alt=''></img>
					</div>
					<div className='row'>
						<img src='/img/01.jpeg' class='img-fluid rounded-top' alt=''></img>
					</div>
				</div>
				<div className='row'>
					<h2>Typical stock of equipment supplied and delivered for a street surveillance system</h2>
					<img src='/img/stock01.jpeg' class='img-fluid rounded-top' alt=''></img>
					<img src='/img/stock02.jpeg' class='img-fluid rounded-top' alt=''></img>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

