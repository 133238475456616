import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';


export default function profile() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Company Profile</h2>
					<a href="/doc/Afrisec_Master.pdf">(Download)</a>
					<p className="pt-2">The Afrisec Group has over 35 years of experience as designers and implementers of Security Master Plans and integrated security systems covering a wide spectrum of applications and virtually the entire security spectrum. The Group, based in South Africa, has offices in Port Elizabeth, Bloemfontein, and Pretoria and has the following divisions:</p>
					<div class='row'>
						<div class='col-12'>
							<ul>
								<li>Master Plans Division</li>
								<li>Manufacturing Installation Division</li>
								<li>Maintenance Division</li>
								<li>Training Division</li>
							</ul>
						</div>
					</div>

					<div className='row'>
						<div className='col-2'>
							<img src='/img/building-block.png' class='w-100' alt=''></img>
						</div>
						<div className='col-9'>
							<p>
								Afrisec's integrated system include the
								<strong>DESIGN, MANUFACTURING, INSTALLATION, MAINTENANCE, SPECIALIST TRAINING PROGRAMMES, FIRE MANAGEMENT, EMERGENCY MANAGEMENT, BUILDING MANAGEMENT SYSTEMS</strong>
								and
								<strong>RISK MANAGEMENT</strong>
								of large-scale projects.
							</p>

							<p>Our security solutions have received acclaim for its international security services by leaders in this field, from CEO’s of large corporations to political leaders in the USA, Great Britain, the Middle East, South Africa and other African countries</p>
						</div>
					</div>

					<h2>Customised Solutions</h2>
					<p>Afrisec has built up extensive intellectual property over the years and specializes in devising a total security solution for each client, beginning with analysis of the threat facing the client, the design of a Security Master Plan to address that threat and the implementation of integrated security systems to carry out the mandate of the Security Master Plan.</p>
					<p>Projects already successfully implemented include Master Plans and security, followed by extensive training programmes in various specialist fields, for:</p>

					<div class='row'>
						<div class='col-9'>
							<ul>
								<li>Metropolitan areas</li>
								<li>Regions</li>
								<li>Military bases</li>
								<li>Police bases</li>
								<li>Sensitive government complexes</li>
								<li>One of the largest hydro electric dam complexes in the world</li>
								<li>Central banks</li>
								<li>Maximum security prisons, and</li>
								<li>Oil refineries</li>
							</ul>
						</div>
						<div class='col-3'>
							<img src='/img/defender-design-small-blue.png' class='img-fluid rounded-top' alt=''></img>
						</div>
						<p>The Afrisec Group has the intellectual and physical capacity to deliver backup services to ensure that a Master Plan, once implemented by Afrisec, remains on track afterwards, creating the environment for well-structured economic development programmes.</p>
						<p>The Afrisec Group consists of the following divisions:</p>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

