import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export default function Home() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
				<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Introduction</h2>
					<p>Afrisec offers total security solutions. We conduct a thorough threat analysis, after which a Security Master Plan is designed to combat the threat.</p>
					<p>We then implement the Integrated Security System required by the Security Master Plan, which includes:</p>
					<div class='row'>
						<div class='col-6'>
							<ul>
								<li>Design</li>
								<li>Manufacturing</li>
								<li>Installation</li>
								<li>Maintenance</li>
								<li>Specialist training programmes</li>
							</ul>
						</div>
						<div class='col-6'>
							<ul>
								<li>Fire Management Networks</li>
								<li>Emergency Management Networks</li>
								<li>Building Management Networks</li>
								<li>Risk Management Networks</li>
							</ul>
						</div>
					</div>
					<h2>Master Plans</h2>
					<p>Our Master Plans and the implementation of Integrated Security Systems are based on the practical expert knowledge gained over a 30-year period by our directors, executive and advisors. We focus on defining the problem and putting phase-based innovative structures in place. </p>
					<p>Our products include:</p>
					<div class='row'>
						<div class='col-3'>
							<ul>
								<li>High capacity Central and Satellite Control Rooms</li>
								<li>Maximum security steel doors, gates and windows creating an alarm when cut – specialised alarm tubing (Afrisec patent)</li>
								<li>Security fences and walls in varying degrees of security hardness</li>
								<li>Revolving pins (Afrisec patent) on top of walls/fences</li>
								<li>Asset and personnel tracking systems</li>
								<li>HVAC monitoring and control</li>
							</ul>
						</div>
						<div class='col-3'>
							<ul>
								<li>Building Management Systems i.e. energy usage monitoring, water and waste management, and industrial process control</li>
								<li>Time and Attendance Systems</li>
								<li>Security Light Networks</li>
								<li>Intercom, Alarm, Fire and CCTV Networks (including Street Surveillance)</li>
								<li>Voice logging</li>
								<li>Software design and programming, producing management reports and functioning as a management control system</li>
								<li>Supermax lock system (Afrisec patent), installed at sensitive areas</li>
							</ul>
						</div>
						<div class='col-3'>
							<ul>
								<li>Advanced Access Control Networks - fingerprint readers, staff/visitor control, vehicle control</li>
								<li>High-powered stun gun (Afrisec patent)</li>
								<li>High-powered stun belt (Afrisec patent)</li>
								<li>High-powered stun shield (Afrisec patent)</li>
								<li>Defender Surveillance and Defence System (we do not however supply firearms)</li>
								<li>Alarm tubing steel structures (Afrisec patent)</li>
								<li>Maximum security locks (Afrisec patent)</li>
							</ul>
						</div>
						<div class='col-3'>
							<ul>
								<li>Revolving pins (Afrisec patent)</li>
								<li>Mobile Surveillance</li>
								<li>Rapid deployment systems</li>
								<li>Networking (Implementation and Design)</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

