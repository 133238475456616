import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';


export default function Segway() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Segway</h2>
					<div class='row pt-5'>
						<div class='col-3'>
							<img src='/img/segway.png' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-9'>
							<p>
								The Segway Personal Transporter (PT) represents the next generation in personal mobility, offering several advancements that make the ride even more intuitive and productive than ever before. Other examples of Segway Smart Motion™ technology and licensing of Segway Smart Motion technology to other third-party companies, demonstrate that Segway continues to challenge the assumptions
								and expand the boundaries of traditional transportation.”
							</p>
							<p>The Afrisec Group is a licensed distributor of the Segway Personal Transporter (PT) and provides the following assistance: Training Surveillance and, Support.</p>
							<p>'The Segway' for consumers: “You can choose between the versatile and sleek flagship model, the Segway i2 with lithium-ion batteries standard; the Segway x2, or six different fully equipped packages tailored to such uses as commuting and golf.</p>
							<p>
								The Segway i2 is a great way to enjoy the ride while commuting to work, running errands, or just travelling a short distance where a car is more hassle than it's worth. The Segway x2 brings added versatility to your world, offering sturdy capability in rugged off-sidewalk terrains such as trails, bike paths or beachfronts. The Segway x2 Golf package is an innovative, fun and
								flexible way to play around. And remember, fun is engineered into every Segway PT we build!”
							</p>
							<p>'The Segway' for businesses: Businesses worldwide have discovered the enhanced productivity and increased mobility inherent to the versatile, reliable and durable Segway i2. The Segway i2 has been developed to excel in speed, range, and manoeuvrability in varied environments.</p>
							<p>
								It comes standard with lithium-ion batteries for extended range of up to 24 miles on a single charge. Packages tailored to the needs of security and commercial customers are now available and an additional range of accessories can be added for true customization. Police and security agencies worldwide are using Segway products to patrol airports, transit stations, manufacturing
								facilities, shopping malls and campuses. For businesses operating in outdoor environments, the Segway x2 maximizes performance on diverse off-sidewalk terrain. This rugged version of our venerable Segway PT makes tackling rough terrain a breeze. Equipped with standard lithium-ion batteries and low-pressure tires.
							</p>
							Please visit  <a href="www.segway.co.za">www.segway.co.za</a>  for further information.
						</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

