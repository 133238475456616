import React from 'react';

export default function Navbar() {
	return (
		<nav className='navbar navbar-expand-lg navbar-dark bg-dark static-top fixed-top '>
			<div className='container-fluid'>
				<a className='navbar-brand' href='#'>
					<img src='/img/logo.png' alt='...' height='36'></img>
					<span className='mx-3'>Afrisec Group</span>
				</a>
				<button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
					<span className='navbar-toggler-icon'></span>
				</button>
				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='navbar-nav ms-auto'>
						<li className='nav-item'>
							<a className='nav-link' aria-current='page' href='/'>
								Home
							</a>
						</li>
						<li className='nav-item dropdown'>
							<a className='nav-link dropdown-toggle' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								Company
							</a>
							<ul className='dropdown-menu dropdown-menu-end' aria-labelledby='navbarDropdown'>
								<li>
									<a className='dropdown-item' href='/accreditation'>
										Accreditation
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/profile'>
										Profile
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/objectives'>
										Objectives
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/ourstaff'>
										Our Staff
									</a>
								</li>
							</ul>
						</li>
						<li className='nav-item dropdown'>
							<a className='nav-link dropdown-toggle' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								Implementations
							</a>
							<ul className='dropdown-menu dropdown-menu-end' aria-labelledby='navbarDropdown'>
								<li>
									<a className='dropdown-item' href='/implementation_private'>
										Private Sector
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/implementation_public'>
										Public Sector
									</a>
								</li>
							</ul>
						</li>
						<li className='nav-item dropdown '>
							<a className='nav-link dropdown-toggle' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								Products and Services
							</a>
							<ul className='dropdown-menu dropdown-menu-end' aria-labelledby='navbarDropdown'>
								<li>
									<a className='dropdown-item' href='/segway'>
										Segway
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='mobilevehicle'>
										Mobile Surveillance Vehicle
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/mobiletrailer'>
										Mobile Surveillance Trailer
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/fencing'>
										Fencing
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/streetsurveillance'>
										Street Surveillance and Control Rooms
									</a>
								</li>
							</ul>
						</li>
						<li className='nav-item dropdown'>
							<a className='nav-link dropdown-toggle' href='#' id='navbarDropdown' role='button' data-bs-toggle='dropdown' aria-expanded='false'>
								Media
							</a>
							<ul className='dropdown-menu dropdown-menu-end' aria-labelledby='navbarDropdown'>
								<li>
									<a className='dropdown-item' href='/pressroom'>
										Press Room
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='socialmedia'>
										Social Media Responsibility
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/casestudy'>
										Case Study
									</a>
								</li>
								<li>
									<a className='dropdown-item' href='/img/camprosa.pdf'>
										COMPROSA 2018
									</a>
								</li>
							</ul>
						</li>
						<li className='nav-item'>
							<a className='nav-link' aria-current='page' href='/renewable'>
								Renewable Solutions
							</a>
						</li>
						<li className='nav-item'>
							<a className='nav-link' aria-current='page' href='/contactus'>
								Contact Us
							</a>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
}

