import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export default function Mobilevehicle() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Mobile Surveillance</h2>

					<div class='row'>
						<div class='col-2'>
							<img src='/img/msv-article.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>

						<div class='col-10'>
							<p>The first SA built MSV is based on the Mercedes Sprinter 4x4 platform and provides long range, 360°, day and night surveillance capability, even under adverse weather conditions. The vehicle is expected to play an important role in South African border control and crime fighting operations in the future.</p>

							<p>
								The MSV features state-of-the-art Carl Zeiss sensors, including a daylight CCD camera with 36x zoom lens, a high resolution thermal imager for night surveillance and an eye-safe laser rangefinder for target location. The sensors are mounted on a mast which emerges from the vehicle’s roof and is controlled by operators within the vehicle’s control compartment. Video from the sensors
								is monitored by the controllers and the location of suspicious activities is displayed on a digital map to aid reaction forces to locate suspects.
							</p>

						</div>
							<div class="row pt-5">
								<p>
									To reduce operator fatigue, the MSV utilizes sensor automation, automatic target movement detection, target tracking, and situational recording. Video footage can also be beamed to a central control room for remote observation. For under-cover operations the vehicle is fitted with a thermal driver’s camera which enables the driver to drive to a strategic location in total darkness
									without the use of the vehicle’s headlights.
								</p>
								<p>
									Afrisec will deliver the first MSV to the Nelson Mandela Bay Metro, with further orders expected from other leading South African municipalities. Part of the NMBM brief to Afrisec was that the mobile surveillance vehicle shall communicate with the existing NMBM Central Control Room, which Afrisec designed and implemented. Operators in the Central Control Room will be able to
									monitor the thermal images from the mobile unit and the operators in the vehicle will be able to select and monitor from the existing 350 Surveillance Cameras already installed by Afrisec in the CBD and various NMBM buildings.
								</p>
								<p>A primary function of the mobile surveillance vehicle is to combat electrical infrastructure and non ferrous metal theft proactively, by coordinating the mobile unit with the existing Street Surveillance cameras and mobile surveillance trailers that Afrisec has built.</p>
							</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

