import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Accreditation from './pages/accreditation';
import Profile from './pages/profile';
import Objectives from './pages/objectives';
import ImplementationPrivate from './pages/implementationprivate';
import ImplementationPublic from './pages/implementationpublic';
import Ourstaff from './pages/ourstaff';
import Segway from './pages/segway';
import Mobilevehicle from './pages/mobilevehicle';
import { Mobiletrailer } from './pages/mobiletrailer';
import { Streetsurveillance } from './pages/streetsurveillance';
import Pressroom from './pages/pressroom';
import { Casestudy } from './pages/casestudy';
import { Socialmedia } from './pages/socialmedia';
import { Fencing } from './pages/fencing';
import { Contactus } from './pages/contactus';
import Renewable from './pages/renewable';

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<Routes>
					<Route index element={<Home></Home>} />
					<Route path='/' element={<Home></Home>} />
					<Route path='/accreditation' element={<Accreditation></Accreditation>} />
					<Route path='/profile' element={<Profile></Profile>} />
					<Route path='/objectives' element={<Objectives></Objectives>} />
					<Route path='/implementation_private' element={<ImplementationPrivate></ImplementationPrivate>} />
					<Route path='/implementation_public' element={<ImplementationPublic></ImplementationPublic>} />
					<Route path='/ourstaff' element={<Ourstaff></Ourstaff>} />
					<Route path='/segway' element={<Segway></Segway>} />
					<Route path='/mobilevehicle' element={<Mobilevehicle></Mobilevehicle>} />
					<Route path='/mobiletrailer' element={<Mobiletrailer></Mobiletrailer>} />
					<Route path='/streetsurveillance' element={<Streetsurveillance></Streetsurveillance>} />
					<Route path='/pressroom' element={<Pressroom></Pressroom>} />
					<Route path='/casestudy' element={<Casestudy></Casestudy>} />
					<Route path='/socialmedia' element={<Socialmedia></Socialmedia>} />
					<Route path='/fencing' element={<Fencing></Fencing>} />
					<Route path='/contactus' element={<Contactus></Contactus>} />
					<Route path='/renewable' element={<Renewable></Renewable>} />

					{/* <Route path='*' element={<NoPage></NoPage>} /> */}
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;

