import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export default function accredditation() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Accreditation</h2>
					<p>The Afrisec Group is a member of, and accredited by, the following institutions and bodies:</p>

					<div class='row'>
						<div class='col-4'>
							<ul>
								<li>Workmen’s Compensation</li>
								<li>Unemployment Insurance Fund</li>
								<li>PAYE</li>
								<li>VAT</li>
								<li>FIRE</li>
								<li>IT</li>
								<li>CIDB (Construction Industry Development Board) 6SQ PE</li>
							</ul>
						</div>
						<div class='col-4'>
							<ul>
								<li>Skills Development Fund for SDL</li>
								<li>PSIRA</li>
								<li>OHS</li>
								<li>Systems Integrator</li>
								<li>BEE Certified Level 2</li>
								<li>CCTV Certified</li>
								<li>Access Control Certified</li>
							</ul>
						</div>
						<div class='col-4'>
							<ul>
								<li>Systems Installer Certified</li>
								<li>Thermal Cameras Certified</li>
								<li>Alarm Installer Certified</li>
								<li>High Voltage Systems Certified</li>
								<li>Rotakin</li>
								<li>ICASA Licensed</li>
								<li>Siemens Solution Partner</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

