import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export function Socialmedia() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
				<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Community</h2>

					<div class='row'>
						<div class='col-4'>
							<img src='/img/social/quest_school.png' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/polocross-2013.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/polo-x-200.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
					</div>
					<div class='row pt-3'>
						<div class='col-4'>
							<img src='/img/social/ubuntu_2012_2.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/ubuntu-soccer-2013.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/new-brighton.png' class='img-fluid rounded-top' alt=''></img>
						</div>
					</div>
					<div class='row pt-3'>
						<div class='col-4'>
							<img src='/img/social/new-brighton-2.png' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/kwezi-lomzo-school380x280.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/friendly-city-jnrsmall.png' class='img-fluid rounded-top' alt=''></img>
						</div>
					</div>

					<div class='row pt-3'>
						<div class='col-4'>
							<img src='/img/social/football-2011-200.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/football-2010-100.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/afrisec_award_2012.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
					</div>
					<div class='row pt-3'>
						<div class='col-4'>
							<img src='/img/social/baystar-2011-200.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/church-02.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'>
							<img src='/img/social/church-01.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
					</div>
					<div class='row pt-3'>
						<div class='col-4'>
							<img src='/img/social/beachwood_school285.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-4'></div>
						<div class='col-4'></div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

