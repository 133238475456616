import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export default function ImplementationPrivate() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Private Sector</h2>
					<div class='table-responsive'>
						<table class='table table-dark table-bordered table-striped'>
							<thead>
								<tr>
									<th scope='col'>Sub Systems</th>
									<th scope='col'>Solution Provided</th>
									<th scope='col'>Projects</th>
								</tr>
							</thead>
							<tbody>
								<tr class=''>
									<td scope='row'>Integrated Access Control</td>
									<td>Impro Sagem</td>
									<td>Integrated Security Systems: Baywest Mall, SANRAL Building</td>
								</tr>
								<tr class=''>
									<td scope='row'>Time and Attendance</td>
									<td>Sagem</td>
									<td>Integrated Security System: Baywest Mall</td>
								</tr>
								<tr class=''>
									<td scope='row'>Public Address / EVAC</td>
									<td>Hikvision Milestone Axis</td>
									<td>Integrated Security System: Baywest Mall, SANRAL</td>
								</tr>
								<tr class=''>
									<td scope='row'>CCTV</td>
									<td>Hikvision Milestone Axis</td>
									<td>Integrated Security System: Baywest Mall, Panaroti's, Rugs Original, Pandora's Jewellers, Spur, Cafe Rouse, Tea Merchant, SANRAL Building, Sanctuary Mall </td>
								</tr>
								<tr class=''>
									<td scope='row'>Data Network</td>
									<td></td>
									<td>Integrated Security System: Baywest Mall, Sanctuary Mall, SANRAL Building</td>
								</tr>
								<tr class=''>
									<td scope='row'>Fibre Network</td>
									<td></td>
									<td>Integrated Security System: Baywest Mall, Sanctuary Mall</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

