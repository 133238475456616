import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export function Mobiletrailer() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Mobile Surveillance Trailer</h2>

					<div class='row'>
						<div class='col-2'>
							<img src='/img/bw-article.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-10'>
							<p>Afrisec, a Nelson Mandela Metro based leading supplier of integrated security systems, has built nine long range, 360°, day and night Surveillance Trailers for the NMBM, nicknamed “Black Widow”.</p>

							<p>
								All the trailers include the most durable materials and techniques to produce a highly durable, light weight and versatile surveillance concept-trailer. One of the unique features of the trailers is that their containers are manufactured from austenitic Stainless Steel with a satin and ruggedized finish. The trailers have a 3CR12 Stainless Steel chassis with epoxy coating, leaf
								spring suspension with safari shocks. Every nut, bolt, hinge and rivet is Stainless Steel which results in greater durability and less maintenance with our NMBM coastal weather conditions.
							</p>
						</div>
					</div>

					<div className='row'>
						<div className='col-12'>
							<p>
								Each trailer is fitted with a 10 Meter pneumatic lockable mast with compressor and mounting system. Attached to this mast is a dual camera unit which includes a long range Pan, tilt and zoom high resolution thermal imaging camera (capable of detecting a person at 800m and a vehicle 2.5km away and can see though mist, smoke and materials like plastic and paper bags) for night
								surveillance and a 36 x zoom lens high resolution day/night camera. There is also an Infrared Day/Night camera attached to the mast to monitor the trailer and its immediate surrounds.
							</p>

							<p>
								Another unique feature of the trailers is that they have a speaker and Security IP phone sub system installed with bidirectional audio so that the security officer at the trailer can communicate with the Central Control Room and Afrisec’s offsite maintenance Control Room for support. The speaker system allows pre-recorded audio messages, instructions or music to be played through
								the integrated speaker system.
							</p>

							<p>Mounted on each trailer are four LED spotlights which use less energy and provide a bright white light when required for the 36 x zoom day/night camera and to view the area around the trailer.</p>

							<p>The trailers are fitted with batteries and a UPS and can be powered from a normal 220V source or the onboard solar panel and back up generator. This allows the trailer to be in the field or at a remote site/location for long periods.</p>

							<p>For security purposes all the trailers are fitted with intruder detection, CCTV and an asset locator, monitored 24/7 by Safety & Security allowing them to see on a digital map (GIS) the exact location of any trailer in real time to aid reaction forces to locate suspects and perpetrators.</p>

							<p>
								Part of the NMBM brief to Afrisec was that the surveillance trailers shall communicate with each other, the existing NMBM Central Control Room EFMSbw2 article (Enterprise Facilities Management System) System, which Afrisec designed and implemented and the Mobile Surveillance Vehicle. Operators in the Central Control Room can monitor the thermal and day/night images from the
								trailers and the operators in the MSV will be able to select and monitor the different trailer images.
							</p>
						</div>
					</div>

					<div className='row'>
						<div className='col-10'>
							<p>The trailers can also be linked into the NMBM Safety & Security network via a network cable. This allows operators to Pan, Tilt and Zoom the thermal and day/night camera in real time. All footage is recorded for 30 days. Afrisec can also perform real time maintenance checks on the different devices and subsystems on the trailers remotely.</p>

							<p>A primary function of the mobile surveillance vehicle is to combat electrical infrastructure and non ferrous metal theft proactively, by coordinating the MSV with the existing Street Surveillance cameras and mobile surveillance trailers of the NMBM.</p>

							<p>The trailers can be deployed at disasters like flooding or fires, emergencies like sea rescue, special events like VIP visits from national or overseas delegations, the Iron Man Challenge, NMBM Splash Festival or other sporting events at St Georges and the new Nelson Mandela Bay Stadium.</p>

							<p>The Security Sub-Directorate does not have the manpower to garrison all areas of the Metro, meaning technology and intelligence must be used as a force multiplier to allow officers to discharge their mandate which is to protect Council Property, assets and staff.</p>
						</div>
						<div className='col-2'>
							<img src='/img/bw2-article.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

