import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export default function ImplementationPublic() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
				<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Public Sector</h2>
					<div class='table-responsive'>
						<table class='table table-dark table-bordered table-striped'>
							<thead>
								<tr>
									<th scope='col'>Sub Systems</th>
									<th scope='col'>Solution Provided</th>
									<th scope='col'>Projects</th>
								</tr>
							</thead>
							<tbody>
								<tr class=''>
									<td scope='row'>Building Management System (BMS)</td>
									<td>EFMS</td>
									<td>Integrated Security Systems: Central Control Rooms linked to Satellite Control Rooms, Maximum Security Prison</td>
								</tr>
								<tr class=''>
									<td scope='row'>Integrated Access Control</td>
									<td>EFMS Winpak Prowatch Impro Cardkey Sagem Bioscript</td>
									<td>
										<p>Integrated Security System: Central Control Rooms linked to Satellite Control Rooms</p>
										<p>Government Complexes: i.e. Office Complexes, Reserve Banks, Military Bases</p>
										Armoury, Customer Care Centres, Museums
									</td>
								</tr>
								<tr class=''>
									<td scope='row'>Public Address / Intercom Systems</td>
									<td>Cisco Polycom Snom MIDI Aiphone Pantel Communication Solutions</td>
									<td>Integrated Security System:Central Control Rooms linked to Satellite Control Rooms Integrated Security System: Maximum Security Prison Armoury</td>
								</tr>
								<tr class=''>
									<td scope='row'>CCTV</td>
									<td>DVM IndigoVision Beta Tech Pelco DVR's</td>
									<td>Integrated Security System: Central Control Rooms linked to Satellite Control Rooms </td>
								</tr>
								<tr class=''>
									<td scope='row'>Large Screen Display Wallk</td>
									<td>Sony DVM IndigoVision</td>
									<td>Integrated Security System: Central Control Rooms Linked to Satellite Control Rooms</td>
								</tr>
								<tr class=''>
									<td scope='row'>IP Telephony</td>
									<td> Snom Digium Mitel</td>
									<td>Integrated Security System: Central Control Rooms Reserve Bank</td>
								</tr>
								<tr class=''>
									<td scope='row'>Video Screen</td>
									<td> Sony Panasonic LG</td>
									<td>Integrated Security Systems: Central Control Rooms linked to Satellite Control Rooms Street Surveillance CBD Street Surveillance Beach front</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

