import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';


export default function objectives() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
			<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Our Objectives</h2>
					<p>The Afrisec Group’s objective is:</p>
					<div class='row'>
						<div class='col-12'>
							<ul>
								<li>The security environment in which effective Economic Development Master Plans could develop, thereby preventing economic growth from being negatively influenced by security issues, or if influenced, containing deviations within reasonably acceptable parameters.</li>
								<li>A well-structured Security Master Plan, of a City, Region or a Country, followed by an effective implementation and maintenance programme, is an essential and marketable tool for strategic leaders that could be applied professionally to draw in long-term investors’ capital.</li>
								<li>To design and implement purpose-built training programmes to sustain and back up the above programmes.</li>
								<li>To assist with the development of selected economic development programmes To assist with effective Corporate Governance and HR/HRD programmes in order for corporations to reach their objectives.</li>
								<li>To create the security (and related emergency services) environment in which selected economic development programmes could be implemented effectively and for decision makers to be able to focus on their core business without being distracted by security issues.</li>
							</ul>
						</div>
					</div>
					<p>Our group has:</p>
					<div class='row'>
						<div class='col-12'>
							<ul>
								<li>A quality professional management structure, with experience in various fields of expertise, members of which have been in this industry, on average, for the past 20 years. This team has been acknowledged for its expertise in South Africa and also by several African countries, as well as international</li>
								<li>A well trained staff structure with key personnel who have been with the group, on average, for approximately 20 years</li>
								<li>A fully equipped factory, fully equipped offices in several centres and a fleet of vehicles</li>
								<li>A solid financial gearing, conservatively managed</li>
							</ul>
						</div>
					</div>
					<p>Successes: Involve complex projects of magnitude:</p>
					<div class='row'>
						<div class='col-12'>
							<ul>
								<li>Regional Governments,</li>
								<li>Metros,</li>
								<li>Defence,</li>
								<li>Police,</li>
								<li>Maximum Security Prisons,</li>
								<li>Hydro Electric Dams,</li>
								<li>Bunkers, etc</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

