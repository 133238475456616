import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';


export function Fencing() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
				<h2>Fences</h2>
					<div class='row'>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/001.jpg' class='img-fluid rounded-top' alt=''></img>
							Mesh fence
						</div>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/002.jpg' class='img-fluid rounded-top' alt=''></img>
							Mesh fence
						</div>
					</div>
				</div>
				<div class='row pt-5'>
					<div class='row'>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/003.jpg' class='img-fluid rounded-top' alt=''></img>
							Mesh fence
						</div>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/004.jpg' class='img-fluid rounded-top' alt=''></img>
							Mesh fence
						</div>
					</div>
				</div>
				<div class='row pt-5'>
					<div class='row'>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/005.jpg' class='img-fluid rounded-top' alt=''></img>
							Custom Fencing
						</div>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/006.jpg' class='img-fluid rounded-top' alt=''></img>
							Fence security
						</div>
					</div>
				</div>
				<div class='row pt-5'>
					<div class='row'>
						<div class='col-6'>
							<img className='card-img-top' src='/img/fencing/007.jpg' class='img-fluid rounded-top' alt=''></img>
							Custom Fencing
						</div>

					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

