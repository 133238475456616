import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function ControlledCarousel() {
	const [index, setIndex] = useState(0);

	const handleSelect = (selectedIndex) => {
		setIndex(selectedIndex);
	};

	return (
		<Carousel activeIndex={index} onSelect={handleSelect}>
			<Carousel.Item>
				{/* <ExampleCarouselImage text="First slide" /> */}
				<img src='/img/header/002.jpg' class='card-img-top' alt=''></img>
				<Carousel.Caption>
					<div className=' w-50 text-light bg-dark bg-opacity-75 p-2'>
						{/* <h3>First slide label</h3> */}
						Integrated CCTV Systems
					</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				{/* <ExampleCarouselImage text="Second slide" /> */}
				<img src='/img/header/003.jpg' class='card-img-top' alt=''></img>

				<Carousel.Caption>
					<div className=' w-50 text-light bg-dark bg-opacity-75 p-2'>
						{/* <h3>Second slide label</h3> */}
						Central Control Rooms
					</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				{/* <ExampleCarouselImage text="Third slide" /> */}
				<img src='/img/header/004.jpg' class='card-img-top' alt=''></img>

				<Carousel.Caption>
					<div className=' w-50 text-light bg-dark bg-opacity-75 p-2'>Mobile Surveillance Trailers</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				{/* <ExampleCarouselImage text="Third slide" /> */}
				<img src='/img/header/005.jpg' class='card-img-top' alt=''></img>

				<Carousel.Caption>
					<div className=' w-50 text-light bg-dark bg-opacity-75 p-2'>Mobile Surveillance Vehicles</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				{/* <ExampleCarouselImage text="Third slide" /> */}
				<img src='/img/header/006.jpg' class='card-img-top' alt=''></img>

				<Carousel.Caption>
					<div className=' w-50 text-light bg-dark bg-opacity-75 p-2'>Thermal Cameras</div>
				</Carousel.Caption>
			</Carousel.Item>
			<Carousel.Item>
				{/* <ExampleCarouselImage text="Third slide" /> */}
				<img src='/img/header/007.jpg' class='card-img-top' alt=''></img>

				<Carousel.Caption>
					<div className=' w-50 text-light bg-dark bg-opacity-75 p-2'>Renewable Energy</div>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
}

export default ControlledCarousel;

