import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';


export function Casestudy() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Case Study</h2>
					<h3>IndigoVision’s IP Video Provides CCTV for South African Metro Project</h3>
					<div class='row'>
						<div class='col-2'>
							<img src='/img/case_clip_image002_0000.jpg' class='img-fluid rounded-top' alt=''></img>
						</div>
						<div class='col-10'>
							<p>
								IndigoVision’s integrated IP Video system is providing the CCTV surveillance for an impressive integrated security system for the Nelson Mandela Bay Metropolitan Municipality located in the Eastern Cape province of South Africa. The metro area is home to over 1.3 million people, covers an area of 2000 km2 and includes the towns of Uitenhage and Despatch, together with Port
								Elizabeth which will be a host city for the 2010 Soccer World Cup.
							</p>

							<p>
								The IP-CCTV system, which was supplied by Norbain South Africa, is part of an integrated security system that includes access control, intruder detection, perimeter security and fire detection all of which were designed and installed by Afrisec. The systems are fully integrated across a newly installed 1000 Gbit LAN running on a fibre backbone, with wireless network links for more
								remote sites. The system will also provide services such as IP telephony and intercoms. The Safety & Security Directorate, which operates the system, worked closely with Afrisec, who produced the initial Security Master Plan from which the system was implemented. The initial phase of the project provides CCTV surveillance of the Port Elizabeth Central Business District (CBD) and
								the along the beach front, using 45 fixed and PTZ dome cameras, monitored at a Central Security Control Room located in the South End district. There are also other satellite control rooms in Metro buildings in the CBD and on the Beachfront. Operators based in all the control rooms use IndigoVision’s ‘Control Center’ software to view live video from the cameras and analyse recorded
								footage from IndigoVision Network Video Recorders (NVRs), which provide 30+ days of recordings from all cameras.
							</p>
						</div>
					</div>

					<div class='row'>
						<div class='col-12'>
							<p>
								The distributed nature of IndigoVision’s IP Video system allows a ‘Control Center’ workstation to be located at any point on the network, facilitating the use of multiple security control rooms. The IP Video system is tightly integrated with the other Building Management and Security Systems used on the project providing a platform for true integration, so that an event in one
								system can trigger an action in another. For example, an illegal entry alarm from the access control system can automatically cause the nearest CCTV camera to pan and zoom to view the entry point and display the video on any ‘Control Center’ workstation for immediate operator response. As the system expands IndigoVision’s real-time analytics will be installed on certain cameras.
								This will alert operators to events as they occur, such as vehicles moving in the wrong direction, congestion or a virtual tripwire being crossed. This dramatically reduces the time to locate critical events, enabling timely response to potential incidents. The Metro area has 236 potential sites that require security. These will be implemented based on priority and available
								budgets. Other strategic sites, such as the airport, which have their own existing CCTV system, will be updated to IP Video so they can be monitored centrally as well. Here the existing analogue cameras will be connected to IndigoVision’s transmitter/receiver modules, which convert the analogue camera feeds to high-quality MPEG digital video for transmission over the network. This
								effectively creates a hybrid CCTV solution for the airport, whereby the airport maintains its existing analogue system whilst allowing remote monitoring and recording of the video over the network.
							</p>
							<p>
								The scalability of the IP Video system allows other surveillance areas to be added easily in the future. For example, the fibre backbone currently runs through the centre of the CBD, allowing businesses and other private buildings to be monitored from any of the control rooms. A CCTV camera can be connected to any point on the network without the need for additional cabling. The
								flexibility provided by a distributed IP Video system cannot be matched by a traditional analogue CCTV solution. When the Soccer World Cup comes to Port Elizabeth in 2010 transportation routes, tourist hotspots, hotels and the stadium will need to be monitored via the central control room – all of which is very cost effective now that the network infrastructure is in place.
							</p>
							For further information contact IndigoVision: UK and Rest of World - Tel: +44 131 475 7200, Fax: +44 131 475 7201 Americas - Tel: +1 908 315 0286, Fax: +1 908 822 0031 E-mail: info.request@indigovision.com or visit <a href='www.indigovision.com'>www.indigovision.com</a>
						</div>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

