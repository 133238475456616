import { Map, Marker, OverlayLayer, SVGPin } from '@jetblack/map';
import HeaderCarousel from '../components/HeaderCarousel';
import Navbar from '../components/navbar';

const AfrisecPE = {
	latitude: -33.97395,
	longitude: 25.61928,
};

export function Contactus() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
				<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Contact Detail</h2>
					<table class='table table-dark table-bordered table-striped'>
						<thead>
							<tr>
								<th scope='col'>Branch</th>
								<th scope='col'>Contact Name</th>
								<th scope='col'>Email Address</th>
								<th scope='col'>Telephone Number</th>
							</tr>
						</thead>
						<tbody>
							<tr class=''>
								<td scope='row'>Bloemfontein</td>
								<td>Johan Barnard</td>
								<td>johan@afrisecgroup.co.za</td>
								<td></td>
							</tr>
							<tr class=''>
								<td scope='row'></td>
								<td>Chris Barnard (Jnr)</td>
								<td></td>
								<td></td>
							</tr>
							<tr class=''>
								<td scope='row'>Port Elizabeth</td>
								<td>Gary Johnson</td>
								<td>gary@afrisecgroup.co.za</td>
								<td>
									<p>+2741 581 7328</p>
									<p>+2741 581 7375</p>
								</td>
							</tr>
						</tbody>
					</table>
					<h2>Address</h2>
					<div className='col-12 text-center'>
						<Map width='100%' height='400px' center={AfrisecPE} zoom={18}>
							<OverlayLayer>
								<Marker coordinate={AfrisecPE} render={(point) => <SVGPin point={point} />} />
							</OverlayLayer>
						</Map>
						264 Walmer Boulevard, Port Elizabeth
					</div>
				</div>
			</div>
		</div>
	);
}

