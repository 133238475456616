import React from 'react';
import Navbar from '../components/navbar';
import HeaderCarousel from '../components/HeaderCarousel';

export default function Ourstaff() {
	return (
		<div>
			<Navbar></Navbar>
			<div className='container p-5 mt-4'>
<HeaderCarousel></HeaderCarousel>
				<div class='row pt-5'>
					<h2>Our Staff</h2>
					<p>Some of our technical and admin staff.</p>
					<div className='row'>
						<img src='/img/staff_800.jpg' class='img-fluid rounded-top' alt=''></img>
					</div>
					<div className='row pt-5'>
						<img src='/img/year-end-function.jpg' class='img-fluid rounded-top' alt=''></img>
					</div>
				</div>
			</div>

			<footer class='text-center text-lg-start bg-light text-muted'>
				<div class='text-center p-4 bg-dark text-light'>
					© 2023 Copyright:
					<a class='text-reset fw-bold' href='http://www.afrisecgroup.co.za/'>
						Afrisec Group
					</a>
				</div>
			</footer>
		</div>
	);
}

